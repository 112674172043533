import { getMessaging, getToken } from 'firebase/messaging'

import { trackEvent } from './analytics/events'

import { analyticsEvents } from '~/res/constants/events'

// tracking events

export const sendPermissionDeniedEvent = (type: string) => {
  trackEvent({
    name: 'click_interaction',
    label: analyticsEvents.notifications.denied(type),
    interaction_type: 'button',
  })
}

export const sendNotSupportedEvent = (type: string) => {
  trackEvent({
    name: 'click_interaction',
    label: analyticsEvents.notifications.notSupported(type),
    interaction_type: 'button',
  })
}

// push notifications

export const PUSH_ERRORS = {
  PERMISSION_DENIED: 'Permission denied',
  UNSUPPORTED_BROWSER: 'Unsupported browser',
}

export const iOSPushPermissionEventName = 'push-permission-request'
export const iOSVerifyPushPermissionEventName = 'push-permission-state'

export const iOSRequestPushPermissionEvent = () => {
  window.webkit.messageHandlers[iOSPushPermissionEventName].postMessage(
    iOSPushPermissionEventName,
  )
}

export const iOSVerifyPushPermissionEvent = () => {
  window.webkit.messageHandlers[iOSVerifyPushPermissionEventName].postMessage(
    iOSVerifyPushPermissionEventName,
  )
}

export const hasIOSPushCapability = () =>
  window &&
  window.webkit &&
  window.webkit.messageHandlers &&
  window.webkit.messageHandlers['push-permission-request']

export interface PushNotificationToken {
  detail: {
    token: string
    state: string
  }
}

export interface PushNotificationPermission {
  detail:
    | 'notDetermined'
    | 'denied'
    | 'authorized'
    | 'provisional'
    | 'ephemeral'
    | 'unknown'
}

export const verifyWebNotificationPermission = () =>
  window && 'Notification' in window && Notification.permission === 'granted'

export const getWebFirebaseToken = async () => {
  const registration = await navigator.serviceWorker.ready

  if (!registration) {
    throw new Error('Service worker not found')
  }

  const messaging = getMessaging()

  const token = await getToken(messaging, {
    vapidKey: window.env?.firebaseVapidKey,
    serviceWorkerRegistration: registration,
  })

  return token
}

export const buildErrorDescription = (error?: string) => {
  if (error === PUSH_ERRORS.UNSUPPORTED_BROWSER) {
    return 'unsupported' as const
  }
  return 'denied' as const
}
